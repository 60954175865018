import React from "react";
import "../../style/job.css";
import JobSearch from "../components/job-serach";
const Job = () => {
  return (
    <div className="job-page">
      <JobSearch />     
    </div>
  );
};

export default Job;
